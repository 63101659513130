(function ($) {

    $.when(
      $.get("Scripts/cldr/sk/ca-gregorian.json"),
      $.get("Scripts/cldr/sk/numbers.json"),
      $.get("Scripts/cldr/sk/timeZoneNames.json"),
      $.get("Scripts/cldr/likelySubtags.json"),
      $.get("Scripts/cldr/numberingSystems.json"),
      $.get("Scripts/cldr/timeData.json"),
      $.get("Scripts/cldr/weekData.json")
    ).then(function () {

        // Normalize $.get results, we only need the JSON, not the request statuses.
        return [].slice.apply(arguments, [0]).map(function (result) {
            return result[0];
        });

    }).then(Globalize.load).then(function () {
        Globalize.locale("sk");

        $.validator.addMethod("mandatory", function (value, element, params) {
            var result = false;

            $("input[data-val-groupname='" + params.groupname + "']").each(function () {
                result = result || $(this).prop("checked");
            });

            return result;
        }, '');

        $.validator.unobtrusive.adapters.add('mandatory', ['groupname'], function (options) {
            options.rules['mandatory'] = options.params;
            options.messages['mandatory'] = options.message;
        });

        $.validator.addMethod("enforcetrue", function (value, element, param) {
            var $validationLevel = $('#FormData_Statement_ValidationLevel');
            return element.checked || $validationLevel.val() === "none";
        });

        $.validator.unobtrusive.adapters.addBool("enforcetrue");

        $.validator.unobtrusive.adapters.addBool("datenoworhigher");
        $.validator.addMethod("datenoworhigher", function (value, element) {
            if (value) {
                var date = parseSlovakDate(value);
                var now = new Date();
                now.setHours(0, 0, 0, 0);
                return date >= now;
            }
            return true;
        });

        $.validator.unobtrusive.adapters.addBool("datenoworlower");
        $.validator.addMethod("datenoworlower", function (value, element) {
            if (value) {
                var date = parseSlovakDate(value);
                date.setHours(0, 0, 0, 0);
                var now = new Date();
                now.setHours(0, 0, 0, 0);
                return date <= now;
            }
            return true;
        });

        $.validator.addMethod("dategreaterorequal", function (value, element, params) {
            var namePrefix = getElementPrefix(element.name);
            var startdatevalue = $('input[name="' + namePrefix + params.otherdatepropertyname + '"]').val();
            if (!value || !startdatevalue) return true;
            return parseSlovakDate(startdatevalue) <= parseSlovakDate(value);
        }, '');
        $.validator.unobtrusive.adapters.add('dategreaterorequal', ['otherdatepropertyname'], function (options) {
            options.rules['dategreaterorequal'] = options.params;
            options.messages['dategreaterorequal'] = options.message;
        });

        // ico
        $.validator.unobtrusive.adapters.addBool("icoextended");
        $.validator.addMethod("icoextended", function (value, element) {
            if (value) {                
                // remove spaces
                value = value.replace(/\s/g, '');

                var regex = /^\d{8}$/g;
                if (!regex.test(value))
                    return false;

                // add missing zeros
                if (value.length < 8)
                    value = ('00000000' + value).substring(value.length);

                var checkSum = 0;
                var checkNum = parseInt(value.slice(-1)); //posledna cislica ICO je kontrolna

                // checksum evaluation
                for (var i = 2; i <= 8; i++) {
                    var n = parseInt(value[value.length - i]);
                    checkSum += n * i;
                }

                // checknum evaluation
                var num = 11 - checkSum % 11;
                if (num === 10) num = 0;
                else if (num === 11) num = 1;

                return num === checkNum;
            }
            return true;
        });

        // slovaktaxtid
        $.validator.unobtrusive.adapters.addBool("slovaktaxtid");
        $.validator.addMethod("slovaktaxtid", function (value, element) {
            if (value) {
                var regex = /^\d{10}$/g;
                if (!regex.test(value))
                    return false;

                return parseInt(value) % 11 === 0;
            }
            return true;
        });

        // slovakvatid
        $.validator.unobtrusive.adapters.addBool("slovakvatid");
        $.validator.addMethod("slovakvatid", function (value, element) {
            if (value) {
                var regex = /^SK\d{10}$/g;
                if (!regex.test(value))
                    return false;

                var value2 = value.replace('SK', '');
                return parseInt(value2) % 11 === 0;
            }
            return true;
        });

        function getElementPrefix(name) {
            return name.substr(0, name.lastIndexOf('.')) + '.';
        }

        function parseSlovakDate(date) {
            var parts = date.split(".");
            return new Date(parts[2] + '-' + parts[1] + '-' + parts[0]);
        }
    });


}(jQuery));