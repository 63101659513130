(function ($) {

    $.widget("ui.catcomplete", $.ui.autocomplete, {
        _renderMenu: function (ul, items) {
            var self = this;

            var currentCategory = "";
            $.each(items, function (index, item) {
                if (item.category != undefined) {
                    if (item.category != currentCategory) {
                        if (item.CategoryUrl === undefined || item.CategoryUrl === '') {
                            ul.append("<li class='ui-autocomplete-category'>" + item.category + "</li>");
                        }
                        else {
                            ul.append("<li class='ui-autocomplete-category-anchor'><a href=" + item.CategoryUrl + " class='ui-autocomplete-category'>" + item.category + "</a></li>");
                        }
                        currentCategory = item.category;
                    }
                }
                self._renderItemData(ul, item);
            });
        },

        status: function (val) {
            var self = this;
            var statusCtrl = $('.auto-complete-loading', self.element.parent());

            if (val === undefined) {
                statusCtrl.removeClass('auto-complete-search auto-complete-notfound');
            }
            else if (val == true) {
                statusCtrl.removeClass('auto-complete-notfound');
                statusCtrl.addClass('auto-complete-search');
            }
            else {
                statusCtrl.removeClass('auto-complete-search');
                statusCtrl.addClass('auto-complete-notfound');
            }
            return true;
        }

    });
})(jQuery);
